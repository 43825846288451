*{
    box-sizing: border-box;
}

html, 
body,
#app{
  min-height:100%;
  height: 100%;
  margin:0;
  padding: 0;
}

html{
    font-size: 20px;
    line-height: 1.5;
    min-width: 300px;
}
@media only screen and (max-width: 900px) {
    html{
        font-size: 18px;
        line-height: 1.4;
    }
  }

  @media only screen and (max-width: 600px) {
    html{
        font-size: 16px;
        line-height: 1.3;
    }
  }

  main > section{
      max-width: 900px;
      margin: auto;
  }
