

main{
  height: 100%;
}

.overview{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; 
  min-height: 90%;
  padding: 0 1rem;
  margin-bottom: 4rem;
}

.btn.add_meal__button{
  display: block;
  position: fixed;
  background-color: #3fd05a;
  height: 3rem;
  width: 3rem;
  border: 0 solid transparent;
  color: white;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 0.5rem;
}
.btn.add_meal__button:visited{
  background-color: #3fd05a;
}
.btn.add_meal__button .material-icons{
  font-size: 2rem;
}
